import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBTooltip } from "mdb-react-ui-kit";
import React from "react";
import fireEvent from "../../utils/fireEvent";
import "./ESD.css"
import METERS_TO_FEET_DIVISIOR from "../../utils/meters_to_feet_divisor";
import timeFormat from "../../TimeFormatter/TimeFormatter";
import ESDAudio from "./audio/ESDAudio";

class ESDModal extends React.Component {
    constructor(props) {
        super(props);
        this.dataMgr = props.dataMgr;
        this.settingsMgr = props.settingsMgr;
        this.state = {
            stopData: this.dataMgr.getStopInfo(1),
            ESDModalVisible: false,
            stopId: 1,
            forwardArrowDisabled: false,
        }
        this.THE_COUNTRIES = ["United States", "Netherlands", "Philippines", "United Arab Emirates", "Democratic Republic of the Congo", "Central African Republic", "French Southern and Antarctic Lands", "British Indian Ocean Territory", "Dominican Republic"]
    }

    toggleESDModalVisible = () => {
        setTimeout(() => {
            document.getElementById("esdModalDialog").scrollIntoView()
        }, this.ESDModalVisible ? 5 : 250)
        this.setState({ESDModalVisible: !this.state.ESDModalVisible})
    }

    setESDModalVisible_wrap = (newstate) => {
        this.setState({ESDModalVisible: newstate})
    }

    determineForwardArrowDisabled() {
        return this.state.stopId + 1 >= this.dataMgr.nextStopId
    }

    handleESDEvent(e) {
        this.setState({stopId: e.detail.index, stopData: this.dataMgr.getStopInfo(e.detail.index), ESDModalVisible: true})
        this.determineForwardArrowDisabled()
    }

    handleOnStopDeparture(e) {
        this.setState({forwardArrowDisabled: this.determineForwardArrowDisabled()})
    }

    renderLocalArrival() {
        if (!this.settingsMgr.settings.esdLocalTimeVisible) {
            return ""
        }
        let prevstop_data = this.dataMgr.getStopInfo(this.state.stopId - 1)
        let nextstop_data = this.dataMgr.getStopInfo(this.state.stopId + 1)

        let localarrival = timeFormat(prevstop_data.unixarrival, this.state.stopData.unixarrival, nextstop_data.unixarrival, "esd_local", this.state.stopData.timezone, this.state.stopData.locale, this.settingsMgr)
        
        let userarrival_compare_str = timeFormat(prevstop_data.unixarrival, this.state.stopData.unixarrival, nextstop_data.unixarrival, "esd_compare", undefined, undefined, this.settingsMgr)
        let localarrival_compare_str = timeFormat(prevstop_data.unixarrival, this.state.stopData.unixarrival, nextstop_data.unixarrival, "esd_compare_local", this.state.stopData.timezone, this.state.stopData.locale, this.settingsMgr)

        let userarrival_dt = new Date(userarrival_compare_str)
        let localarrival_dt = new Date(localarrival_compare_str)

        if (userarrival_dt.getHours() !== localarrival_dt.getHours() || userarrival_dt.getMinutes() !== localarrival_dt.getMinutes() || userarrival_dt.getDate() !== localarrival_dt.getDate()) {
            return (
                <>
                <b>Local Arrival Time:</b> {localarrival}<br></br>
                </>
            )
        } else {
            return ""
        }
    }

    renderFlagTitle() {
        if (this.state.stopData.region === "") {
            return ""
        } else if (this.THE_COUNTRIES.indexOf(this.state.stopData.region) !== -1 || this.state.stopData.region.includes("Islands")) {
            return "Flag of the " + this.state.stopData.region
        } else {
            return "Flag of " + this.state.stopData.region
        }
    }

    renderUkraineESD() {
        if (this.state.stopData.countrycode === "ua" || this.state.stopData.countrycode === "ru") {
            return (
                <>
                <b>We strongly condemn Russia's unprovoked and unjustified invasion of Ukraine. We stand with Ukraine in their fight for freedom and for all Ukrainians affected by the invasion.</b>
                <hr></hr>
                </>
            )
        } else {
            return <></>
        }
    }
    
    renderPalestineESD() {
        if (this.state.stopData.countrycode === "il" || this.state.stopData.countrycode === "ps") {
            return (
                <>
                <b>We strongly condemn Israel's ongoing genocide in Palestine. We stand with all Palestinians affected by the ongoing genocide, and urge for an immediate, permanent ceasefire to end the suffering amongst millions of innocent Palestinians. Free Palestine&nbsp;</b><i class="fi fi-ps"></i>
                <hr></hr>
                </>
            )
        } else {
            return <></>
        }
    }

    componentDidMount() {
        document.addEventListener("esdLaunch", this.handleESDEvent.bind(this))
        document.addEventListener("esdLaunch_tap", this.handleESDEvent.bind(this))
        document.addEventListener("stopDeparture", this.handleOnStopDeparture.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener("esdLaunch", this.handleESDEvent.bind(this))
        document.removeEventListener("esdLaunch_tap", this.handleESDEvent.bind(this))
        document.removeEventListener("stopDeparture", this.handleOnStopDeparture.bind(this))
    }

    renderArrivalTime(tzcontext, tzlocale) {
        let prevstop_data = this.dataMgr.getStopInfo(this.state.stopId - 1)
        let nextstop_data = this.dataMgr.getStopInfo(this.state.stopId + 1)

        return timeFormat(prevstop_data.unixarrival, this.state.stopData.unixarrival, nextstop_data.unixarrival, "esd", tzcontext, tzlocale, this.settingsMgr)
    }

    renderWeatherIcon() {
        if (this.settingsMgr.settings.esdWeatherSummaryVisible) {
            return (
                <i class={"wi " + this.state.stopData.weather.icon}></i>
            )
        } else {
            return (
                <MDBTooltip tag="i" wrapperProps={{ className: "wi " + this.state.stopData.weather.icon}} title={this.state.stopData.weather.summary} placement="bottom"/>
            )
        }
    }

    renderStopElevation() {
        if (this.state.stopData.city === "International Space Station") {
            if (this.settingsMgr.get("units_actual") === "imperial") {
                return "Orbiting 260 miles above Earth"
            } else {
                return "Orbiting 418 km above Earth"
            }
        }

        return this.settingsMgr.get("units_actual") === "imperial" ? 
                Math.round(this.state.stopData.elevation / METERS_TO_FEET_DIVISIOR).toLocaleString() + " ft" 
                : this.state.stopData.elevation.toLocaleString() + " m"
    }

    renderWikipediaReadMore() {
        if (this.state.stopData.region !== "") {
            if (this.state.stopData.region.split(", ").length > 1) {
                return this.state.stopData.city + ", " + this.state.stopData.region.split(", ")[1]
            } else {
                return this.state.stopData.city + ", " + this.state.stopData.region
            }
        } else {
            return this.state.stopData.city
        }
    }

    renderKiribatiNote() {
        if (this.state.stopData.region === "Kiribati") {
            return (
                <>
                    <hr></hr>
                    <b>Note: </b>The text-to-speech pronunciation for Kiribati is incorrect. It is meant to be pronounced like KIRR-ih-BASS.
                </>
            )
        }
    }

    render() {
        return (
            <>
            <MDBModal id="esdModal" appendToBody show={this.state.ESDModalVisible} setShow={this.setESDModalVisible_wrap} tabIndex='-1'>
                <MDBModalDialog id="esdModalDialog">
                    <MDBModalContent>
                        <MDBModalHeader className='esdHeader'>
                            <MDBModalTitle className='esdTitle'>
                                <span title={this.renderFlagTitle()} className={"fi fi-" + this.state.stopData.countrycode}></span>{this.state.stopData.countrycode !== "zz" ? (<>&nbsp;&nbsp;</>) : ""}{this.state.stopData.city + (this.state.stopData.region !== "" ? ", " + this.state.stopData.region : "")}
                            </MDBModalTitle>
                            <ESDAudio dataMgr={this.dataMgr} settingsMgr={this.settingsMgr} esdModalVisible={this.state.ESDModalVisible} />
                            <MDBBtn className='esdNav-left' color='secondary' onClick={() => {fireEvent("esdLaunch", {index: this.state.stopId - 1})}} disabled={this.state.stopId - 1 <= this.dataMgr.ptEnds + 1} title="Click to show information about the previous stop">
                                <KeyboardArrowLeft fontSize="small"></KeyboardArrowLeft>
                            </MDBBtn>
                            <MDBBtn className='esdNav-right' color='secondary' onClick={() => {fireEvent("esdLaunch", {index: this.state.stopId + 1})}} disabled={this.determineForwardArrowDisabled()} title="Click to show information about the next stop">
                                <KeyboardArrowRight fontSize="small"></KeyboardArrowRight>
                            </MDBBtn>
                            <MDBBtn style={{ marginLeft: "0.5rem !important" }} className='esdClose btn-close' color='none' onClick={this.toggleESDModalVisible} title="Click to close this modal"></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <b>Arrived: </b> {this.renderArrivalTime()}<br></br>
                            {this.renderLocalArrival()}
                            <b>Weather:</b>&nbsp;&nbsp;
                            {this.renderWeatherIcon()}
                            &nbsp;&nbsp;{this.settingsMgr.get("units_actual") === "imperial" ? this.state.stopData.weather.tempF + "°F" : this.state.stopData.weather.tempC + "°C"}{this.settingsMgr.settings.esdWeatherSummaryVisible ? " · " + this.state.stopData.weather.summary : ""}<br></br>
                            <b>Elevation: </b>{this.renderStopElevation()}<br></br>
                            <b>Population: </b>{this.state.stopData.population.toLocaleString()} {this.state.stopData.population_year === "0" ? "" : `(${this.state.stopData.population_year})`}<br></br>
                            <hr></hr>
                            {this.renderPalestineESD()}
                            {this.renderUkraineESD()}
                            {this.state.stopData.descr}
                            <hr></hr>
                            <a href={this.state.stopData.srclink} className="linkwrap" target="_blank" rel="noreferrer">Read more about {this.renderWikipediaReadMore()} on Wikipedia</a><br></br>
                            <small>Weather data powered by <a href="https://weatherkit.apple.com/legal-attribution.html" target="_blank" rel="noreferrer">Apple Weather</a></small>
                            {this.renderKiribatiNote()}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={this.toggleESDModalVisible} title="Click to close this modal">
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            </>
        )
    }
}

export default ESDModal