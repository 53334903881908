import { RecordVoiceOver, VoiceOverOff, Error } from "@mui/icons-material";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import React from "react";
import "./ESDAudio.css"
import fireEvent from "../../../utils/fireEvent";
import { acceptableLocales } from "./consts";

const md5 = require("md5")

class ESDAudio extends React.Component {
    constructor(props) {
        super(props);
        this.settingsMgr = props.settingsMgr
        this.dataMgr = props.dataMgr
        this.state = {
            audioPlaying: false,
            loading: false,
            errored: false,
            cannotPlayAudio: false,
            fqn: undefined
        }
        
        this.acceptableLocales = acceptableLocales

        this.audioElement = undefined;

        this.timeoutErrorTimeout = undefined;
        this.clearErrorStateTimeout = undefined;
    }

    initializeAudioElement() {
        this.workingLocale = this.settingsMgr.get("pronunciationLocale")
        if (this.workingLocale === "automatic") {
            if (this.acceptableLocales.indexOf(navigator.language) !== -1) {
                this.workingLocale = navigator.language
            } else {
                this.workingLocale = "en-US"
            }
        }

        clearTimeout(this.timeoutErrorTimeout)
        clearTimeout(this.clearErrorStateTimeout)
        this.setState({loading: true, errored: false})
        let hashsum = md5(this.state.fqn)
        this.audioElement = new Audio()
        if (!this.audioElement.canPlayType("audio/mpeg")) {
            this.setState({cannotPlayAudio: true, errored: true})
            fireEvent("alertShow", {"message": "Your browser cannot play audio files.", "timeout": 7.5, "severity": "danger", "nonblocking": false})
            // In this case don't reset the errored state.
            return
        }
        this.audioElement.title = this.state.fqn
        this.audioElement.addEventListener("canplay", (event) => {
            this.audioElement.playbackRate = this.settingsMgr.get("pronunciationPlaybackSpeed")
            this.audioElement.play()
            this.setState({loading: false, audioPlaying: true})
        })

        // Add an event for when playback ends
        this.audioElement.addEventListener("ended", (event) => {
            this.setState({audioPlaying: false})
        })

        this.audioElement.addEventListener("error", (event) => {
            fireEvent("alertShow", {"message": "There was an error trying to load the audio file.", "timeout": 7.5, "severity": "danger", "nonblocking": false})
            this.setState({errored: true, loading: false})
            clearTimeout(this.clearErrorStateTimeout)
            this.clearErrorStateTimeout = setTimeout(() => {
                this.setState({errored: false})
            }, 7500)
        })

        this.audioElement.src = `/assets/audio/${this.workingLocale}/${hashsum}.mp3`
        this.audioElement.load()
        this.timeoutErrorTimeout = setTimeout(() => {
            // If the audio element is still loading after 15 seconds, error it out.
            if (this.state.loading) {
                this.audioElement.pause()
                this.audioElement.currentTime = 0
                fireEvent("alertShow", {"message": "There was a timeout error trying to load the audio file.", "timeout": 7.5, "severity": "danger", "nonblocking": false})
                this.setState({loading: false, errored: true})
                clearTimeout(this.clearErrorStateTimeout)
                this.clearErrorStateTimeout = setTimeout(() => {
                    this.setState({errored: false})
                }, 7500)
            }
        }, 15000)
    }

    onButtonClick() {
        if (this.state.cannotPlayAudio || this.state.errored) {
            return
        } else if (!this.state.audioPlaying && this.audioElement === undefined) {
            this.initializeAudioElement()
        } else if (!this.state.audioPlaying && this.audioElement !== undefined) {
            this.audioElement.play()
            this.setState({audioPlaying: true})
        } else if (this.state.audioPlaying) {
            this.audioElement.pause()
            this.audioElement.currentTime = 0
            this.setState({audioPlaying: false})
        }
    }

    onESDLaunch(e) {
        if (this.audioElement !== undefined) {
            this.audioElement.pause()
            this.audioElement = undefined;
        }

        let stopData = this.dataMgr.getStopInfo(e.detail.index)

        this.setState({
            fqn: stopData.city + ", " + stopData.region,
            audioPlaying: false,
            loading: false,
            errored: false
        })

        clearTimeout(this.clearErrorStateTimeout)
        clearTimeout(this.timeoutErrorTimeout)
    }

    componentDidMount() {
        document.addEventListener("esdLaunch", this.onESDLaunch.bind(this))
        document.addEventListener("esdLaunch_tap", this.onESDLaunch.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener("esdLaunch", this.onESDLaunch.bind(this))
        document.removeEventListener("esdLaunch_tap", this.onESDLaunch.bind(this))
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.esdModalVisible && this.audioElement !== undefined && this.state.audioPlaying) {
            this.audioElement.pause()
            this.audioElement = undefined
            this.setState({audioPlaying: false})
        }
        return true
    }

    renderButtonTitle() {
        if (this.state.loading) {
            return "Voiceover is loading"
        } else if (this.state.audioPlaying) {
            return "Click to stop listening to the pronunciation of this city"
        } else if (this.state.cannotPlayAudio) {
            return "Error: Your browser cannot play audio files"
        } else if (this.state.errored) {
            return "Failed to load the voiceover. Please try again."
        } else {
            return "Click to listen to the pronunciation of this city"
        }
    }

    renderButtonIcon() {
        if (this.state.loading) {
            return (
                <MDBSpinner size='sm' />
            )
        } else if (this.state.audioPlaying) {
            return (
                <VoiceOverOff fontSize="small" />
            )
        } else if (this.state.errored) {
            return (
                <Error fontSize="small" />
            )
        } else {
            return (
                <RecordVoiceOver fontSize="small" />
            )
        }
    }

    render() {
        return (
            <MDBBtn className={this.state.loading ? "esdAudio-loading" : "esdAudio"} color='secondary' onClick={() => {this.onButtonClick()}} title={this.renderButtonTitle()}>
                {this.renderButtonIcon()}
            </MDBBtn>
        )
    }
}

export default ESDAudio