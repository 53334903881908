import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdb-react-ui-kit"
import React from "react"
import PreTrackCountdown from "./PreTrackCountdown"

class PreTrackTimerBox extends React.Component {
    constructor(props) {
        super(props)
        this.dataMgr = props.dataMgr
    }

    render() {
        return (
            <>
                <MDBCard className='shadow-3' style={{ pointerEvents: "auto" }}>
                    <MDBCardBody>
                        <MDBCardTitle>
                            Time until liftoff
                        </MDBCardTitle>
                        <MDBCardText>
                            <PreTrackCountdown dataMgr={this.dataMgr} rendercontext="notmobile" />
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </>
        )
    }
}

export default PreTrackTimerBox