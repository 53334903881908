import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdb-react-ui-kit";
import React from "react";
import PreTrackMetric from "./PreTrackMetric";
import PreTrackCountdown from "./PreTrackCountdown";

class PreTrackMetricBox extends React.Component {
    constructor(props) {
        super(props);
        this.dataMgr = props.dataMgr;
        this.state = {
            cardTitle: this.renderCardTitle()
        }
    }

    onPreTrackRouteStateChange(e) {
        this.setState({cardTitle: this.renderCardTitle()})
    }

    componentDidMount() {
        document.addEventListener("preTrackRouteStateChange", this.onPreTrackRouteStateChange.bind(this))
    }

    componentWillUnmount() {
        document.addEventListener("preTrackRouteStateChange", this.onPreTrackRouteStateChange.bind(this))
    }

    renderCardTitle() {
        if (this.dataMgr.ptRouteState === 1) {
            return "Baskets wrapped"
        } else if (this.dataMgr.ptRouteState >= 2) {
            return "Baskets loaded"
        }
    }

    render() {
        return (
            <>
                <MDBCard className='shadow-3' style={{ pointerEvents: "auto"}}>
                    <MDBCardBody>
                        <MDBCardTitle>
                            {this.renderCardTitle()}
                        </MDBCardTitle>
                        <MDBCardText>
                            <PreTrackMetric dataMgr={this.dataMgr} rendercontext="notmobile" />
                            <div className="mobileMetrics-pt-countdown">
                                <small>
                                    <PreTrackCountdown dataMgr={this.dataMgr} rendercontext="mobile" />
                                </small>
                            </div>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </>
        )
    }
}

export default PreTrackMetricBox