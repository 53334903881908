import { DateTime } from "luxon"

let timeformat_info = DateTime.DATETIME_FULL
let timeformat_esd = {...DateTime.DATETIME_FULL, timeZoneName: 'short'}
// UD on ESD local/precision since we manually show the timezone abbreviation or whatever
let timeformat_esd_local = {...DateTime.DATETIME_FULL, timeZoneName: undefined}
let timeformat_esd_local_compare = {...DateTime.DATETIME_MED, timeZoneName: undefined}

let filtering_strings = ["GMT+", "GMT-", "UTC+", "UTC-"]

// MAKE SURE BEFORE & AFTER ARE 0 IF YOU WANT ONE TIME FORMATTED.
export default function timeFormat(before, time, after, context, tzcontext, tzlocale, settingsMgr) {
    let workingformat = timeformat_info;

    if (context === "info") {
        workingformat = timeformat_info
    } else if (context === "esd_local") {
        workingformat = timeformat_esd_local
    } else if (context === "esd_compare" || context === "esd_compare_local") {
        workingformat = timeformat_esd_local_compare
    } else {
        workingformat = timeformat_esd
    }



    let timestr;

    if (context === "esd_compare") {
        timestr = DateTime.fromSeconds(time).setLocale("en-US").toLocaleString(workingformat)
    } else if (context === "esd_compare_local") {
        timestr = DateTime.fromSeconds(time, {zone: tzcontext}).setLocale("en-US").toLocaleString(workingformat)
    } else if (tzcontext !== undefined) {
        // For now we can assume the tzcontext is just for ESD
        // There's a known bug where when you use timeformat_esd_precision (or local precision, probably) in UK localization, you WON'T get a leading zero on the hours
        // but you will get a leading zero on timeformat_esd (non-precise). 
        timestr = DateTime.fromSeconds(time, {zone: tzcontext}).toLocaleString({...workingformat, "hourCycle": settingsMgr.get_actual_clockstyle()}) + " " + DateTime.fromSeconds(time, {zone: tzcontext}).setLocale(tzlocale).toFormat("ZZZZ")
    } else {
        timestr = DateTime.fromSeconds(time).toLocaleString({...workingformat, "hourCycle": settingsMgr.get_actual_clockstyle()})
    }

    // If browsers return -3, -7, etc for an offset, this won't work. But we won't query for that.
    // We also ignore straight GMT (because some places really have GMT for their timezone). Rather we look for GMT+/GMT-.
    if (context !== "esd_local") {
        for (let filter_string of filtering_strings) {
            if (timestr.includes(filter_string)) {
                let splitted = timestr.split(filter_string)
                return splitted[0].substring(0, splitted[0].length - 1)
            }
        }
    }
    return timestr
}