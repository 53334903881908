import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";

class FullscreenButton extends React.Component {
    constructor(props) {
        super(props);
        this.settingsMgr = props.settingsMgr;
        this.state = {
            isFullScreen: document.fullscreen,
            fullScreenAbility: document.fullscreenEnabled, 
        }
    }

    setTitleText() {
        if (document.fullscreen) {
            this.setState({titleText: "Click to exit fullscreen mode"})
        } else {
            this.setState({titleText: "Click to enter fullscreen mode"})
        }
    }

    async onclick() {
        if (this.state.isFullScreen) {
            await document.exitFullscreen()
            this.setState({isFullScreen: document.fullscreen})
        } else if (!this.state.isFullScreen) {
            await document.getElementsByTagName("html")[0].requestFullscreen()
            this.setState({isFullScreen: document.fullscreen})
        }
        this.setTitleText()
    }

    rendericon() {
        if (this.state.isFullScreen) {
            return (<FullscreenExit fontSize="small"></FullscreenExit>)
        } else {
            return (<Fullscreen fontSize="small"></Fullscreen>)
        }
    }

    determineBrowserCompatibility() {
        return this.state.fullScreenAbility;
    }

    onFullScreenChange() {
        this.setState({isFullScreen: document.fullscreen})
        this.setTitleText()
    }

    componentDidMount() {
        document.addEventListener("fullscreenchange", this.onFullScreenChange.bind(this))
        this.setTitleText()
    }

    componentWillUnmount() {
        document.removeEventListener("fullscreenchange", this.onFullScreenChange.bind(this))
    }

    render() {
        if (this.determineBrowserCompatibility()) {
            return (
                <>
                <MDBBtn onClick={this.onclick.bind(this)} style={{ marginTop: "8px", paddingTop: "8px !important", paddingBottom: "8px !important", pointerEvents: "auto" }} title={this.state.titleText}>
                    {this.rendericon()}
                </MDBBtn><br></br>
                </>
            )
        } else {
            return (<></>)
        }
    }
}

export default FullscreenButton
