import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import "./Shutdown.css";

class ShutdownBase extends React.Component {
    render() {
        return (
            <MDBRow className='align-items-center countdown-row' style={{ position: "fixed", height: "100%", width: "100%", margin: "0px", padding: "0px", textAlign: "center" }}>
                <MDBCol>
                    <h2 style={{ fontWeight: 400 }} className="shutdown-h2">
                        track.easterbunny.cc has shut down following Easter 2024 and will no longer run.
                    </h2>
                    <h4 style={{ fontWeight: 400 }}>
                        For more information, <a href="/news/#pid-141" target="_blank" rel="noopener">please read the news post discussing the shutdown</a>. 
                    </h4>
                    <h4 style={{ fontWeight: 400, marginBottom: "0px" }}>
                        Source code is available on <a href="https://gitlab.com/track-easterbunny-cc/tebcc" target="_blank" rel="noreferrer">GitLab</a>.
                    </h4>
                </MDBCol>
            </MDBRow>
        )
    }
}

export default ShutdownBase