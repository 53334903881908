import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle } from "mdb-react-ui-kit"
import React from "react"
import PreTrackCountdown from "./PreTrackCountdown"
import PreTrackMetric from "./PreTrackMetric"

class PreTrackStatus extends React.Component {
    constructor(props) {
        super(props)
        this.dataMgr = props.dataMgr
        this.settingsMgr = props.settingsMgr
        this.state = {
            stopData: this.dataMgr.getLastStopDetails(),
            mobileMetricsVisible: this.settingsMgr.settings.mobileMetricsVisible
        }
    }

    onStopDataUpdate(e) {
        this.setState({stopData: this.dataMgr.getLastStopDetails()})
    }

    onSettingChange(e) {
        if (e.detail.setting === "mobileMetricsVisible") {
            this.setState({mobileMetricsVisible: this.settingsMgr.settings.mobileMetricsVisible})
        }
    }

    componentDidMount() {
        document.addEventListener("stopDeparture", this.onStopDataUpdate.bind(this))
        document.addEventListener("settingChanged", this.onSettingChange.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener("stopDeparture", this.onStopDataUpdate.bind(this))
        document.removeEventListener("settingChanged", this.onSettingChange.bind(this))
    }



    render() {
        return (
            <>
                <MDBCard className='shadow-3' style={{ pointerEvents: "auto" }}>
                    <MDBCardBody>
                        <MDBCardTitle>
                            Last seen
                        </MDBCardTitle>
                        <MDBCardText>
                            <b>{this.state.stopData.city.split("-")[0]}</b>-{this.state.stopData.city.split("-").splice(1).join("-")}
                            <div className="mobileMetrics">
                                <small>
                                    {this.state.mobileMetricsVisible ? (
                                        <>
                                        <PreTrackMetric dataMgr={this.dataMgr} rendercontext="mobile" /><br></br>
                                        </>
                                    ) : ""}
                                    <PreTrackCountdown dataMgr={this.dataMgr} rendercontext="mobile" />
                                </small>
                            </div>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </>
        )
    }
}

export default PreTrackStatus