import './App.css';
import '@webcomponents/custom-elements';
import React from 'react';
import TrackerBase from './tracker/TrackerBase';

export default function App(props) {
  return (
    <TrackerBase />
  )
}
