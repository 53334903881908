import fireEvent from "../utils/fireEvent"

class ShutdownBanner {
    constructor(props) {
        this.settingsMgr = props.settingsMgr
        this.dataMgr = props.dataMgr
    }

    fireShutdownBanner() {
        if (this.dataMgr.routeState < 3 && !this.settingsMgr.get("haveShownShutdownBanner") && process.env.REACT_APP_SHUTDOWN_BANNERS_ENABLED === 'true') {
            setTimeout(() => {
                fireEvent("alertShow", {message: (<>track.easterbunny.cc will no longer run after Easter 2024. <a href='/news/#pid-141' target='blank' rel='noreferrer' style={{pointerEvents: "auto"}}>Read the announcement here</a>.</>), severity: "success", nonblocking: "false", timeout: 10})
                this.settingsMgr.set("haveShownShutdownBanner", true)
            }, 2500)
        }
    }
}

export default ShutdownBanner