import React from "react"

class PreTrackMetric extends React.Component {
    constructor(props) {
        super(props)
        this.dataMgr = props.dataMgr;
        this.rendercontext = props.rendercontext
        this.state = {
            metricsData: this.dataMgr.getPretrackingMetrics(new Date().getTime() / 1000),
            metricTitle: this.renderMetricTitle()
        }
    }

    onRegularUpdate(e) {
        this.setState({metricsData: this.dataMgr.getPretrackingMetrics(e.detail.ts)})
    }

    onPreTrackRouteStateChange(e) {
        this.setState({metricTitle: this.renderMetricTitle()})
    }

    componentDidMount() {
        document.addEventListener("regularUpdate", this.onRegularUpdate.bind(this))
        document.addEventListener("preTrackRouteStateChange", this.onPreTrackRouteStateChange.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener("regularUpdate", this.onRegularUpdate.bind(this))
        document.removeEventListener("preTrackRouteStateChange", this.onPreTrackRouteStateChange.bind(this))
    }

    renderMetricTitle() {
        if (this.dataMgr.ptRouteState === 1) {
            return "Baskets wrapped"
        } else if (this.dataMgr.ptRouteState >= 2) {
            return "Baskets loaded"
        }
    }

    render() {
        if (this.rendercontext === "mobile") {
            return (
                <span><b>{this.state.metricTitle}</b>: {this.state.metricsData.baskets.toLocaleString()}</span>
            )
        } else {
            return (
                <span>{this.state.metricsData.baskets.toLocaleString()}</span>
            )
        }
    }
}

export default PreTrackMetric