import React from "react"

class HelpSettings extends React.Component {
    constructor(props) {
        super(props)
        this.settingsMgr = props.settingsMgr
        this.dataMgr = props.dataMgr
        this.state = {
            windowHeight: window.innerHeight,
            windowWidth: window.innerWidth
        }
        this.geoMetricsErrorState = props.geoMetricsErrorState
    }

    onWindowSizeChange(e) {
        this.setState({windowHeight: window.innerHeight, windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener("resize", this.onWindowSizeChange.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowSizeChange.bind(this))
    }

    redactCoordinate(field) {
        field = field.toString().split(".")
        if (field[1] !== undefined) {
            field[1] = Array(field[1].length + 1).join("X")
        }
        return field.join(".")
    }

    renderSettingsJSON() {
        let localSettings = JSON.parse(JSON.stringify(this.settingsMgr.settings))

        localSettings.curLocLat = this.redactCoordinate(localSettings.curLocLat)
        localSettings.curLocLng = this.redactCoordinate(localSettings.curLocLng)

        if (localSettings.curLocLocation.length > 5) {
            localSettings.curLocLocation = "(redacted)"
        }

        return JSON.stringify(localSettings, null, 2)
    }

    wrapPlatformRender() {
        if (this.settingsMgr.browser.platform.type === "mobile") {
            return "Phone"
        } else if (this.settingsMgr.browser.platform.type === "desktop") {
            return "Desktop"
        } else if (this.settingsMgr.browser.platform.type === "tablet") {
            return "Tablet"
        } else if (this.settingsMgr.browser.platform.type === "tv") {
            return "TV"
        } else if (this.settingsMgr.browser.platform.type === "" || this.settingsMgr.browser.platform.type === undefined) {
            return "N/A"
        } else {
            return this.settingsMgr.browser.platform.type
        }
    }

    render() {
        return (
            <>
            <h5>Tracker Help</h5>
            If you're having problems with the tracker, there's plenty of ways to hopefully solve your issue. <br></br><br></br>
            Common issues can be fixed by resetting tracker settings to their defaults & refreshing the tracker, or by using the tracker in an incognito/private window. Also try seeing if your issue is listed in the <a href="/knownissues" target="_blank">Known Issues</a> page.<br></br><br></br>
            If your issue still persists, you can contact us on Twitter (<a href="https://twitter.com/bunny_tracking" target="_blank" rel="noreferrer">@bunny_tracking</a>) and DM us for help. We can also provide support over email: <a href="mailto:support@easterbunny.cc" target="_blank" rel="noreferrer">support@easterbunny.cc</a>. <br></br><br></br>
            Support is available throughout tracking and we'll respond within a couple of hours.
            <hr></hr>
            <h5>Diagnostic information</h5>
            When receiving support we may ask you for your diagnostic information. Please copy the entirety of the below fields - this will help us troubleshoot your problems. If your browser/OS version looks incorrect, be sure to correct that before sending us this info.<br></br><br></br>
            <b>Browser: </b><code>{this.settingsMgr.browser.browser.name} {this.settingsMgr.browser.browser.version === undefined ? "" : this.settingsMgr.browser.browser.version}</code><br></br>
            <b>OS: </b><code>{this.settingsMgr.browser.os.name} {this.settingsMgr.browser.os.version === undefined ? "" : this.settingsMgr.browser.os.version}</code><br></br>
            <b>Platform: </b><code>{this.wrapPlatformRender()}</code><br></br>
            <b>Engine: </b><code>{this.settingsMgr.browser.engine.name} {this.settingsMgr.browser.engine.version === undefined ? "" : this.settingsMgr.browser.engine.version}</code><br></br>
            <b>Languages: </b><code>{JSON.stringify(navigator.languages)}</code><br></br>
            <b>Window Size: </b><code>{this.state.windowWidth}x{this.state.windowHeight}</code><br></br>
            <b>Device Time: </b><code>{new Date().toString()}</code><br></br>
            <b>Data Sync Time: </b><code>{this.dataMgr.dataSyncTime}</code><br></br>
            <b>Settings: </b><br></br>
            <pre className="code" style={{ marginBottom: "0.25rem" }}>{this.renderSettingsJSON()}</pre>
            <small>Precise location information has been partially redacted for your privacy.</small>
            </>
        )
    }
}

export default HelpSettings