import React from "react";
import InfoButton from "./InfoButton";
import DonateButton from "./DonateButton";
import SettingsButton from "./SettingsButton";
import "./Buttons.css"
import UncenterButton from "./UncenterButton";
import FullscreenButton from "./FullscreenButton";

export default function ButtonsBase(props) {
    if (process.env.REACT_APP_IS_SHUTDOWN === "true") {
        return (
            <div id="buttonsBase" style={{ position: "fixed", pointerEvents: "none"}}>
                <InfoButton settingsMgr={props.settingsMgr} dataMgr={props.dataMgr} />
            </div>
        )
    }

    return (
        <div id="buttonsBase" style={{ position: "fixed", pointerEvents: "none" }}>
            <UncenterButton settingsMgr={props.settingsMgr} dataMgr={props.dataMgr} />
            <FullscreenButton settingsMgr={props.settingsMgr} />
            <SettingsButton settingsMgr={props.settingsMgr} dataMgr={props.dataMgr} geoMetrics={props.geoMetrics} />
            <InfoButton settingsMgr={props.settingsMgr} dataMgr={props.dataMgr} />
            <DonateButton />
        </div>
    )
}