import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBBtn } from "mdb-react-ui-kit"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import React from "react"
import MetricBoxCounter from "./MetricBoxCounter"
import "./Metrics.css"

class MetricBoxBase extends React.Component {
    constructor (props) {
        super(props);
        this.dataMgr = props.dataMgr;
        this.settingsMgr = props.settingsMgr;
        this.geoMetrics = props.geoMetrics;
        this.metricOrder = ["baskets", "carrots", "distancefromyou", "distance", "speed"]
        this.metricOrderByName = {
            "baskets": 0,
            "carrots": 1,
            "distancefromyou": 2,
            "distance": 3,
            "speed": 4
        }

        this.metricButtonTitleById = {
            0: {
                "left": "Click to change the metric shown to Speed",
                "right": "Click to change the metric shown to Carrots eaten"
            },
            1: {
                "left": "Click to change the metric shown to Baskets delivered",
                "right": "Click to change the metric shown to Distance from you"
            },
            2: {
                "left": "Click to change the metric shown to Carrots eaten",
                "right": `Click to change the metric shown to Distance ${this.settingsMgr.traveled_ls === 1 ? "traveled" : "travelled"}`
            },
            3: {
                "left": `Click to change the metric shown to Distance from you`,
                "right": "Click to change the metric shown to Speed"
            },
            4: {
                "left": `Click to change the metric shown to Distance ${this.settingsMgr.traveled_ls === 1 ? "traveled" : "travelled"}`,
                "right": "Click to change the metric shown to Baskets delivered"
            }
        }

        this.state = {
            metricVisible: this.settingsMgr.settings.thirdBox,
            metricVisibleId: this.metricOrderByName[this.settingsMgr.settings.thirdBox[0]],
            metricsInfo: this.dataMgr.getMetrics(new Date().getTime() / 1000),
            dfyMetric: this.geoMetrics.render_distancefromyou(new Date().getTime() / 1000),
            dfyHidden: this.settingsMgr.settings.dfyDisabled,
            units: this.settingsMgr.settings.units
        }
        this.mbc = new MetricBoxCounter({settingsMgr: this.settingsMgr, context: "notmm"})
    }

    renderBoxTitle() {
        if (this.state.metricVisible.length !== 1) {
            return "Metrics"
        }

        if (this.state.metricVisible[0] === "baskets") {
            return "Baskets delivered"
        } else if (this.state.metricVisible[0] === "carrots") {
            return "Carrots eaten"
        } else if (this.state.metricVisible[0] === "distance") {
            return this.settingsMgr.traveled_ls === 1 ? "Distance traveled" : "Distance travelled"
        } else if (this.state.metricVisible[0] === "speed") {
            return "Speed"
        } else if (this.state.metricVisible[0] === "distancefromyou") {
            return "Distance from you"
        }
    }

    changeVisibleMetric(way) {
        let temp_metricVisibleId = this.state.metricVisibleId + way
        if (temp_metricVisibleId >= this.metricOrder.length) {
            temp_metricVisibleId = 0
        } else if (temp_metricVisibleId <= -1) {
            temp_metricVisibleId = 4
        }

        this.settingsMgr.set("thirdBox", [this.metricOrder[temp_metricVisibleId]])
    }

    onSettingChange(e) {
        if (e.detail.setting === "thirdBox") {
            this.setState({metricVisible: this.settingsMgr.settings.thirdBox, metricVisibleId: this.metricOrderByName[this.settingsMgr.settings.thirdBox[0]]})
        } else if (e.detail.setting === "dfyDisabled") {
            this.setState({dfyHidden: this.settingsMgr.settings.dfyDisabled})
        } else if (e.detail.setting === "arrivalMethod") {
            this.setState({dfyMetric: this.geoMetrics.render_distancefromyou(this.dataMgr.getEasterBunnyPosition(new Date().getTime() / 1000))})
        } else if (e.detail.setting === "units") {
            // Hopefully this is enough to get the component to rerender when units change?
            this.setState({units: this.settingsMgr.settings.units})
        }
    }

    onRegularUpdate(e) {
        this.setState({workingTs: e.detail.ts, metricsInfo: this.dataMgr.getMetrics(e.detail.ts)})
    }

    onMapUpdate(e) {
        let workingTs = e.detail.ts
        let positiondata = this.dataMgr.getEasterBunnyPosition(workingTs)
        this.setState({dfyMetric: this.geoMetrics.render_distancefromyou(positiondata)})
    }

    onGeoAPIScaffolded(e) {
        let positiondata = this.dataMgr.getEasterBunnyPosition(new Date().getTime() / 1000)
        this.setState({dfyMetric: this.geoMetrics.render_distancefromyou(positiondata)})
    }

    componentDidMount() {
        document.addEventListener("regularUpdate", this.onRegularUpdate.bind(this))
        document.addEventListener("stopArrival", this.onRegularUpdate.bind(this))
        document.addEventListener("stopArrival", this.onMapUpdate.bind(this))
        document.addEventListener("stopDeparture", this.onRegularUpdate.bind(this))
        document.addEventListener("stopDeparture", this.onMapUpdate.bind(this))
        document.addEventListener("settingChanged", this.onSettingChange.bind(this))
        document.addEventListener("mapUpdate", this.onMapUpdate.bind(this))
        document.addEventListener("geoAPIScaffolded", this.onGeoAPIScaffolded.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener("regularUpdate", this.onRegularUpdate.bind(this))
        document.removeEventListener("stopArrival", this.onRegularUpdate.bind(this))
        document.removeEventListener("stopArrival", this.onMapUpdate.bind(this))
        document.removeEventListener("stopDeparture", this.onRegularUpdate.bind(this))
        document.removeEventListener("stopDeparture", this.onMapUpdate.bind(this))
        document.removeEventListener("settingChanged", this.onSettingChange.bind(this))
        document.removeEventListener("mapUpdate", this.onMapUpdate.bind(this))
        document.removeEventListener("geoAPIScaffolded", this.onGeoAPIScaffolded.bind(this))
    }

    render() {
        return (
            <>
            <MDBCard className='shadow-3' style={{ pointerEvents: "auto" }}>
                <MDBCardBody>
                    <MDBCardTitle>
                        {this.renderBoxTitle()}
                        <div style={{ float: "right", display: this.state.metricVisible.length !== 1 ? "none" : "inline"}}>
                            <MDBBtn className='metricNav-left' color='secondary' onClick={() => {this.changeVisibleMetric(-1)}} title={this.metricButtonTitleById[this.state.metricVisibleId]['left']}>
                                <KeyboardArrowLeft fontSize="small"></KeyboardArrowLeft>
                            </MDBBtn>
                            <MDBBtn className='metricNav-right' color='secondary' onClick={() => {this.changeVisibleMetric(1)}} title={this.metricButtonTitleById[this.state.metricVisibleId]['right']}>
                                <KeyboardArrowRight fontSize="small"></KeyboardArrowRight>
                            </MDBBtn>
                        </div>
                    </MDBCardTitle>
                    <MDBCardText>
                        {this.mbc.render(this.state.metricVisible, this.state.metricsInfo, this.state.dfyMetric, this.state.dfyHidden, this.state.units)}
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
            </>
        )
    }


}

export default MetricBoxBase